.welcome {
  .hero {
    display: flex;
    justify-content: space-between;
    margin-top: -62px;
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 40px;

    img {
      max-width: 100%;
    }
  }
}