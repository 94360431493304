@import
  "~susy/sass/susy",
  "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

.welcome {
  .products {
    @include susy-at($mobile) {
      flex-wrap: wrap;

      .product-card {
        width: span(4);

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    @include susy-at($tablet) {
      .product-card {
        &:not(:last-child) {
          margin-right: 40px;
        }

        width: span(8 / 3);
      }
    }

    @include susy-at($desktop) {
      .product-card {
        &:not(:last-child) {
          margin-right: 40px;
        }

        width: span(4);
      }
    }
  }
}