@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

body {
  &.welcome {
    background-color: $gallery;
  }

  .welcome-card {
    background-color: $white;
    border-radius: 8px;
    padding: 65px 50px 80px;

    p {
      margin-bottom: 75px;
    }

    .button-container {
      display: block;
    }

    .button-container, .logo-container {
      text-align: center;
    }

    .logo-container {
      margin-bottom: 55px;
    }
  }
}