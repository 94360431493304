@import
  "~susy/sass/susy",
  "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

.welcome {
  .hero {
    @include susy-at($mobile) {
      .column-one {
        width: span(3);
      }

      .column-two {
        margin-left: span(0.2);
        text-align: right;
        width: span(0.8);
      }
    }

    @include susy-at($tablet) {
      .column-one {
        width: span(6);
      }

      .column-two {
        margin-left: span(0.5);
        text-align: right;
        width: span(1.5);
      }
    }

    @include susy-at($desktop) {
      .column-one {
        width: span(6);
      }

      .column-two {
        margin-left: span(1);
        text-align: right;
        width: span(5);
      }
    }
  }
}